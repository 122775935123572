




























































import {Component, Vue} from 'vue-property-decorator'
import {getCourseByIdAsync} from '@/api/course'
import {CourseDto} from '@/dto/CourseDto'
import {Lazyload} from 'vant'

Vue.use(Lazyload)

@Component({})
export default class MyCourseInfo extends Vue {
  private courseInfo: CourseDto = {
    coaches: [],
    courseImgs: [],
    category: 0,
    courseTime: '',
    endDateTime: '',
    helpline: '',
    id: '',
    limitNum: 0,
    name: '',
    openingDate: '',
    originalPrice: 0,
    price: 0,
    remark: '',
    startDateTime: '',
    buyNum: 0
  }
  private images = new Array()

  get courseId() {
    return this.$route.query.courseId;
  }

  private async activated() {
    await this.getInfo()
  }

  private async getInfo() {
    if (this.courseId) {
      this.courseInfo = await getCourseByIdAsync(this.courseId);
      if (this.courseInfo.courseImgs.length > 0) {
        this.images = this.courseInfo.courseImgs.map((v: any) => v.fullName)
      }
    }
  }
}
